import { Component, OnInit, Input } from '@angular/core';
import { RoomsService } from '../../services';
import { ShortKeyService } from '../../services/short-key.service';
import { ActivatedRoute } from '@angular/router';

declare const $: any;
@Component({
  selector: 'app-short-key',
  templateUrl: './short-key.component.html',
  styleUrls: ['./short-key.component.css'],
  standalone: false
})
export class ShortKeyComponent implements OnInit {

  public editor
  headShortKey = 'head-short-key-bp';
  shortkeyValue = 'value-short-key-bp';
  bodyShortkey = 'body-short-key-bp';
  constructor(
    private route: ActivatedRoute,
    private roomService: RoomsService,
    public shortKeyService: ShortKeyService) {
  }
  ngOnInit() {
    this.shortkeyValue = 'value-short-key-bp';
    this.headShortKey = 'head-short-key-bp';
    this.bodyShortkey = 'body-short-key-bp';
    this.route.queryParams.subscribe(param => {

      this.roomService.getRoomById(param['roomId']).subscribe((items: any) => {
        this.shortKeyService.refreshShortKey(param['roomId']);
      });


    })
  }

  clickShortkey(value) {
    this.shortKeyService.clickshortkeysBP(value);
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WebsocketService } from './services/websocket.service';
import { BroadcastService } from './services/broadcast.service';
import { ShortKeyService } from './services/short-key.service';
import { TypeLogComponent } from './pages/type-log/type-log.component';
import { ListRoomComponent } from './pages/list-room/list-room.component';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuardService } from './services';
import { ShortKeyComponent } from './pages/short-key/short-key.component';
import { PasteBoradComponent } from './pages/paste-borad/paste-borad.component';
import { SuggestionComponent } from './pages/suggestion/suggestion.component';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { OauthConfigService } from './services/oauth-config.service';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { P404Component } from './pages/error/404.component';
import { BangphimGuard, PostEditGuard } from './services/privilege.guard';
import { DeactivateGuard } from './pages/type-log/deactivate-guard';
import { NgToggleModule } from '@nth-cloud/ng-toggle';
import { ConnectionServiceModule } from 'ng-connection-service';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SettingComponent } from './pages/setting/setting.component';
import { SettingService } from './services/setting.service';
import { RevoiceComponent } from './pages/revoice/main/revoice.component';
import { SuggestionService } from './services/suggestion.service';
import { OnlineUserComponent } from './pages/revoice/online-user/online-user.component';
import { StreamComponent } from './pages/revoice/stream/stream.component';
import { LogTransribeComponent } from './pages/revoice/log-transribe/log-transribe.component';



export function init_app(authConfigService: OauthConfigService) {
  return () => authConfigService.initAuth();
}

@NgModule({
  declarations: [
    AppComponent,
    TypeLogComponent,
    ListRoomComponent,
    LoginComponent,
    ShortKeyComponent,
    PasteBoradComponent,
    SuggestionComponent,
    P404Component,
    RevoiceComponent,
    OnlineUserComponent,
    SettingComponent,
    StreamComponent,
    LogTransribeComponent
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    NgbModule,
    OAuthModule.forRoot(),
    NgToggleModule,
    BrowserAnimationsModule,
    MatProgressBarModule,
    ScrollingModule,
    ConnectionServiceModule
  ],
  providers: [
    AuthGuardService,
    DeactivateGuard,
    WebsocketService,
    BroadcastService,
    PasteBoradComponent,
    ShortKeyService,
    OauthConfigService,
    BangphimGuard,
    PostEditGuard,
    SettingService,
    SuggestionService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [OauthConfigService],
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoomsService {
  offset;
  constructor(private http: HttpClient, private authService: AuthService) { }

  listRoomsByUser(id) {
    const header = new HttpHeaders({
      authorization: this.authService.getToken()
    });
    return this.http.get<any>(environment.API_URL + '/rooms/user/' + id, { headers: header });
  }

  getRoomById(id) {
    return this.http.get(environment.API_URL + '/rooms/' + id);
  }

  setOffset(offset) {
    this.offset = offset;
  }

  getOffset() {
    return this.offset;
  }

  getTypeRoom() {
    return this.http.get(`${environment.API_URL}/type`)
  }

  getStatusRoom() {
    return this.http.get(`${environment.API_URL}/status`)
  }

  getEventRoom(roomId) {
    return this.http.get(`${environment.API_SHELTER}/${roomId}/events`)
  }

  getAllPostedit() {
    return this.http.get(`${environment.API_MANAGEMENT}postedit-room?status=3&page=1&limit=10`)
  }
}
export const environment = {
    production: false,
    API_URL: 'https://api.sandbox.rtt.in.th/v3',
    API_SHELTER: 'https://api.sandbox.rtt.in.th/shelter/v2',
    API_MANAGEMENT: 'https://management-api.sandbox.rtt.in.th/v1/',
    OAUTH: {
        URL: 'https://accounts.sandbox.rtt.in.th/auth/realms/rtt',
        CLIENT_ID: 'bangphim',
        RESONSE_TYPE: 'code'
    },
    version: 'version 2.5.0 - sandbox',
    PE_URL: 'https://postedit.sandbox.rtt.in.th/',
    PE_MULTI_URL: 'https://postedit-ms.sandbox.rtt.in.th/'

};